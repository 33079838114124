<div class="display-files-container">
  <div
    *ngFor="let file of files.filesSelected; let fileIndex = index"
    class="file"
  >
    <ra-ui-input
      id="{{ 'file-name-' + fileIndex }}"
      [label]="file.projectName"
      [config]="{ fillParent: true }"
      [placeholder]="'columnsTable.fileName' | translate"
      [value]="
        'C://Home_Folder/Vault/' +
        files.repositoryName +
        '/' +
        file.projectName +
        '/' +
        file.file
      "
      [prefix]="prefix"
      [raUiTooltip]="
        'C://Home_Folder/Vault/' +
        files.repositoryName +
        '/' +
        file.projectName +
        '/' +
        file.file
      "
      [tooltipConfig]="tooltipConfig"
    >
      <span class="ra-ui-input-prefix">
        <i [class]="file.icon"></i>
      </span>
    </ra-ui-input>
    <i
      [id]="'remove-icon-' + file.file + '-' + fileIndex"
      (click)="onClickRemove(file.projectId, files.repositoryId)"
      class="icon ra-icon-ide-md-remove action-cursor"
      [raUiTooltip]="'pushToVault.remove' | translate"
    ></i>
  </div>
</div>
