<app-title-step
  [iconTitle]="'ra-icon-ide-md-push'"
  [labelTitle]="'pushToVault.btnPushToVault' | translate"
  [numberStep]="numberStep"
  [subtitle]="'pushToVault.selectFiles' | translate"
  [region]="region"
>
</app-title-step>
<div class="content">
  <ra-ui-navigation-tab
    id="navigation-tab-select-files"
    [selectedIndex]="0"
    class="navigation"
  >
    <ra-ui-nav-tab-item
      [label]="'menu.push.recentFilesTab' | translate"
      id="tab-recent-files"
    >
      <div *ngIf="!isDataAvailable && isLoading" class="loading">
        <img
          id="loading-recent-files"
          src="/assets/images/loading-recent-files.svg"
          alt="loading-recent-files"
        />
        <p id="title-loading" class="title-loading">
          {{ "pushToVault.loadingRecentFiles" | translate }}
        </p>
        <ra-ui-loading-spinner
          id="loading-spinner"
          [size]="size"
          [animationType]="animationType"
          [animationDuration]="animationDuration"
        >
        </ra-ui-loading-spinner>
      </div>
      <div *ngIf="!isDataAvailable && !isLoading" class="empty-recent-files">
        <img
          id="empty-folder"
          src="/assets/images/empty-folder.svg"
          alt="empty-folder"
        />
        <p id="title-empty" class="title-empty">
          {{ "pushToVault.emptyListRecentFiles" | translate }}
        </p>
        <p id="subtitle-empty" class="subtitle-empty">
          {{ "pushToVault.instructionEmptyRecentFiles" | translate }}
        </p>
      </div>
      <div *ngIf="recentFiles$ | async">
        <ag-grid-angular
          *ngIf="isDataAvailable"
          id="ag-grid-recent-files"
          class="ag-theme-rockwell"
          [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)"
          (selectionChanged)="onSelectionChanged()"
        >
        </ag-grid-angular>
      </div>
    </ra-ui-nav-tab-item>

    <ra-ui-nav-tab-item
      [label]="'menu.push.homeFolderTab' | translate"
      id="tab-home-folder"
    >
      <app-home-folder
        *ngIf="numberStep === 1"
        (fileSelected)="onHomeFolderFileSelected($event)"
      ></app-home-folder>
    </ra-ui-nav-tab-item>
  </ra-ui-navigation-tab>

  <app-selected-files
    id="selected-files"
    [files]="viewFormData.files"
    [buttonPosition]="buttonPosition"
    (removeFile)="onRemoveFile($event)"
  >
  </app-selected-files>
</div>
