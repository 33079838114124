import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectRepositoryComponent } from './select-repository.component';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { RaUiRadioButtonModule } from '@ra-web-tech-ui-toolkit/form-system/radio-button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SelectRepositoryComponent],
  imports: [
    CommonModule,
    RaUiSearchInputModule,
    RaUiRadioButtonModule,
    TranslateModule,
  ],
  exports: [SelectRepositoryComponent],
})
export class SelectRepositoryModule {}
