import { Injectable } from '@angular/core';
import { Observable, Subscription, of, throwError, lastValueFrom } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import {
  AppState,
  Auth0ClientFactory,
  AuthClientConfig,
  AuthConfig,
  AuthService as Auth0Service,
  User,
} from '@auth0/auth0-angular';
import {
  map,
  tap,
  catchError,
  combineLatestWith,
  shareReplay,
} from 'rxjs/operators';
import {
  ConfigService,
  TwinStudioConfiguration,
} from 'src/app/core/services/config/config.service';
import { RedirectLoginOptions, Auth0Client } from '@auth0/auth0-spa-js';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserProfile } from './user-profile.model';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AuthFacade } from './auth.facade';
import { LCoreResponse } from '../models/lcore-response.model';
import { SessionService } from '../services/session/session.service';
import { getLocalSessionStorageState } from '../reducers/session/session.reducer';
import { EnvironmentNames } from '../../shared/utility/app-utility';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly subscriptions$ = new Subscription();
  private readonly userProfilesCache = new Map<
    string,
    Observable<UserProfile>
  >();

  constructor(
    private readonly storageService: StorageService,
    private readonly auth0Service: Auth0Service,
    private readonly configService: ConfigService<TwinStudioConfiguration>,
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly sessionService: SessionService,
    private readonly authFacade: AuthFacade
  ) {
    this.auth0Service.isAuthenticated$
      .pipe(
        tap((isAuthenticated) => {
          if (isAuthenticated) {
            this.storageService.changes.subscribe((cookieChange) => {
              if (
                cookieChange.key === 'isLogged' &&
                cookieChange.value === 'isFalse'
              ) {
                this.logout();
              }
            });
            this.storageService.start();
            localStorage.setItem('isLogged', 'isTrue');
          } else {
            localStorage.setItem('isLogged', 'isFalse');
          }
        })
      )
      .subscribe();

    this.subscriptions$.add(
      this.http
        .get<User>(
          `https://${this.configService.config.authConfig.domain}/userinfo`
        )
        .subscribe((data) => {
          const userProfile = this.convertToUserProfile(data);
          this.authFacade.loginSuccess(userProfile as UserProfile);
        })
    );
  }

  /**
   * This login method to be called in lower environments(non production) for initiating web login flow
   * @param csTargetPath Optional param required only by the FT-HUB portal login.
   */
  login(csTargetPath?: string): void {
    if (this.configService.config.name === EnvironmentNames.Demo) {
      const returnUrlTwin = 'returnUrlTwin';
      const returnUrl = sessionStorage.getItem(returnUrlTwin);
      const options = {
        appState: {
          target: returnUrl,
        },
      };
      this.auth0Service.loginWithRedirect(options);
    } else {
      const options: RedirectLoginOptions<AppState> = {};
      this.auth0Service.loginWithRedirect(options);
    }
  }

  /**
   * This login method to be called in production for initiating the web login flow
   * @param csTargetPath Optional param required only by the FT-HUB portal login.
   */
  loginWithoutPopup(csTargetPath?: string): void {
    const options: RedirectLoginOptions<AppState> = {};
    if (csTargetPath) {
      options.appState = {
        target: csTargetPath,
      };
    }
    this.auth0Service.loginWithRedirect(options);
  }

  /**
   * This method to be called in for initiating user logout
   */
  logout(): void {
    const sessionLocal = getLocalSessionStorageState();
    if (sessionLocal) {
      this.sessionService.endAllSessions(sessionLocal).subscribe(() => {
        this.endSession();
      });
    } else {
      this.endSession();
    }
  }

  endSession(): void {
    let returnTo = this.configService.config.appBaseUrl + '/sign-in';
    if (this.configService.config.returnToAfterLogout) {
      returnTo = this.configService.config.returnToAfterLogout;
    }
    this.auth0Service.logout({
      returnTo: returnTo,
      federated: true,
    });

    if (this.configService.config.customAuthConfigForLogin) {
      const factoryTalkAppAuthConfig = this.configService.config.authConfig;
      const authConfig: AuthConfig = {
        clientId: factoryTalkAppAuthConfig.clientId,
        domain: factoryTalkAppAuthConfig.domain,
        audience: factoryTalkAppAuthConfig.audience,
        scope: factoryTalkAppAuthConfig.scope,
        connection: factoryTalkAppAuthConfig.connection,
        redirectUri: factoryTalkAppAuthConfig.redirectUri,
        httpInterceptor: factoryTalkAppAuthConfig.httpInterceptor,
        errorPath: factoryTalkAppAuthConfig.errorPath,
        useRefreshTokens: factoryTalkAppAuthConfig.useRefreshToken,
        cacheLocation: factoryTalkAppAuthConfig.cacheLocation,
      };

      const factoryTalkAuthClientConfig = new AuthClientConfig(authConfig);
      const factoryTalkAuth0Client = Auth0ClientFactory.createClient(
        factoryTalkAuthClientConfig
      );

      factoryTalkAuth0Client.logout({
        federated: true,
      });
    }
    this.completeLogout();
  }

  /**
   * This method is used by FT-Hub portal to update state after logout.
   * Used for handling multi-tap logout scenarios.
   */
  completeLogout(): void {
    localStorage.setItem('isLogged', 'isFalse');
  }

  private convertToUserProfile(user: User): UserProfile {
    const namespace = 'https://cloud.rockwellautomation.com/';
    let location = user[namespace + 'City'];
    const region = user[namespace + 'raRegion'];
    if (region) {
      if (location) {
        location = `${location}, ${region}`;
      } else {
        location = `${region}`;
      }
    }
    return {
      userId: user[namespace + 'uid']
        ? user[namespace + 'uid'].replace(/-/g, '')
        : '',
      location: location ?? '',
      company: user[namespace + 'raCompanyName'] ?? '',
      email: user.email ?? '',
      name: `${user.given_name ?? ''} ${user.family_name ?? ''}`,
    } as UserProfile;
  }

  readonly isAuthenticated$ = this.auth0Service.isAuthenticated$;

  getAccessToken$(): Observable<string> {
    return this.auth0Service.getAccessTokenSilently({
      ignoreCache: false,
    });
  }

  async getAccessTokenPromise(): Promise<string> {
    return lastValueFrom(
      this.auth0Service.getAccessTokenSilently({
        ignoreCache: false,
      })
    );
  }

  private throwNotificaitonManagerUndefined() {
    new Error(
      'AuthService: Unexpected Notification auth manager undefined. Load required.'
    );
  }

  factoryTalkUser$ = this.http
    .get<User>(
      `https://${this.configService.config.authConfig.domain}/userinfo`
    )
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly user$: Observable<UserProfile> = this.auth0Service.user$.pipe(
    combineLatestWith(this.factoryTalkUser$),
    map(([user, factoryTalkUser]) => {
      if (user === null || !user) {
        return null;
      }
      const namespace = 'https://cloud.rockwellautomation.com/';

      if (this.configService.config.customAuthConfigForLogin) {
        const ftUserKeys = Object.keys(factoryTalkUser);
        _.forEach(ftUserKeys, (ftUserKey) => {
          if (ftUserKey.startsWith(namespace)) {
            user[ftUserKey] = factoryTalkUser[ftUserKey];
          }
        });
      }
      const userProfile = this.convertToUserProfile(user);
      localStorage.setItem('user', JSON.stringify(user));
      return userProfile;
    })
  );

  /**
   * Checks if the user has a valid auth0 seession,
   * side-effect: If the session is valid extends the session window
   * @returns
   * true if session is valid,
   * false if login is required
   */
  async checkAuth0Session(): Promise<boolean> {
    const appClient = new Auth0Client({
      domain: this.configService.config.authConfig.domain,
      // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
      client_id: this.configService.config.authConfig.clientId,
      audience: this.configService.config.authConfig.audience,
      scope: this.configService.config.authConfig.scope,
      // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
      redirect_uri: this.configService.config.authConfig.redirectUri,
    });

    return new Promise(async function (resolve, reject) {
      try {
        // does this return set cookie
        await appClient.getTokenSilently();
        resolve(true);
      } catch (error: any) {
        if (error.error === 'login_required') {
          resolve(false);
        }
        reject(error);
      }
    });
  }

  /**
   * get the userInfo
   *
   * @param userId: the userId of the user you are looking for
   * @returns: an observable with the userProfile. If the user doesn't exist in
   * the registry or you are not authorized to search that user because he/she
   * doesn't belong to your tenant, you will get an observable with a not available UserProfile
   */

  getUserInfoFor(userId: string | undefined): Observable<UserProfile> {
    if (userId === undefined) {
      return of(this.createNotAvailableUserProfile());
    }

    if (this.userProfilesCache[userId]) {
      return this.userProfilesCache[userId];
    }
    // Have to use sessionStorage because there are issues with using either
    // Tenant or Vault Service --circular referencing
    // Maybe able to change to use it if there are changes in those services.
    const url = `${this.configService.config.lCoreApiBaseUrl}/api/user/${userId}`;
    // cache for future requests in the session
    this.userProfilesCache[userId] = this.http
      .get<LCoreResponse<UserProfile>>(url)
      .pipe(
        map((res) => res.data),
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        catchError((err, _) => {
          if (err instanceof HttpErrorResponse) {
            return of(this.createNotAvailableUserProfile());
          }
          return throwError(err);
        })
      );
    return this.userProfilesCache[userId];
  }

  private createNotAvailableUserProfile() {
    const notAvailable = new UserProfile();
    notAvailable.email = 'Not available';
    notAvailable.name = 'Not available';

    return notAvailable;
  }
}
