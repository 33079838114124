<div class="box-content">
  <div class="title-container">
    <p id="title-selected-files" class="title">
      {{ title }}
    </p>
  </div>

  <div class="selected-files">
    <ng-container *ngIf="!isDataAvailable">
      <div id="empty-container" class="empty-container">
        <div class="image">
          <img
            id="empty-folder"
            src="/assets/images/recent-project-sm.svg"
            alt="empty-folder"
          />
        </div>
        <p id="title-empty" class="title-empty">
          {{ "pushToVault.noFilesSelectedYet" | translate }}
        </p>
        <p id="subtitle-empty" class="subtitle-empty">
          {{ "pushToVault.noFilesSelectedDesc" | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="isDataAvailable">
      <div class="search-input">
        <ra-ui-search-input
          #searchInput
          id="SearchInputRecentFiles"
          [listener]="listener"
          [disabled]="!isDataAvailable"
          [config]="{ predicates: predicates }"
        >
        </ra-ui-search-input>
      </div>

      <div class="file-items">
        <ng-container *ngFor="let file of filesFiltered; let fileIndex = index">
          <ra-ui-input
            #inputRef
            id="{{ 'file-name-' + fileIndex }}"
            formControlName="name"
            [label]="file.filename"
            [placeholder]="'columnsTable.fileName' | translate"
            [(value)]="file.path"
            [prefix]="prefix"
            [raUiTooltip]="file.path"
            [tooltipConfig]="tooltipConfig"
          >
            <span class="ra-ui-input-prefix">
              <img
                id="{{ 'icon-' + fileIndex }}"
                width="20px"
                src="{{ file.iconFilename }}"
                alt="{{ 'icon-' + fileIndex }}"
              />
            </span>
          </ra-ui-input>
          <i
            id="{{ 'remove-icon-' + file.filename + '-' + fileIndex }}"
            (click)="onClickRemove(file.filename)"
            class="icon ra-icon-ide-md-remove action-cursor"
            [raUiTooltip]="'pushToVault.remove' | translate"
          ></i>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
