<div *ngIf="repositories" class="repositories-container">
  <p>{{ "pullFromVault.selectRepository" | translate }}</p>
  <ra-ui-search-input
    #searchInput
    id="search-repository"
    [config]="searchConfig"
    (searchTextChange)="filterByText($event)"
  >
  </ra-ui-search-input>
  <ra-ui-radio-button
    [items]="repositoriesView"
    [config]="config"
    *ngIf="repositoriesView.length > 0"
    (valueChange)="isRepositorySelected($event)"
  >
  </ra-ui-radio-button>
  <p id="noProjects" class="no-projects" *ngIf="repositoriesView.length <= 0">
    {{ "pullFromVault.noProjectsText" | translate }}
  </p>
</div>
